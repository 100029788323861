.error-feedback {
    color: red !important;
}

.grecaptcha-badge {
    visibility: hidden;
}

.btn-group-xs > .btn,
.btn-xs {
    padding: 0.5rem 0.5rem;
    font-size: 0.875rem;
    line-height: 0.5;
    border-radius: 0.2rem;
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .mobile-center {
        text-align: center;
    }
}

@media only screen and (min-width: 800px) {
    .contact-card {
        width: 100% !important;
    }
}

@media only screen and (min-width: 1200px) {
    .contact-card {
        width: 33% !important;
    }
    .modal-larger {
        max-width: 1200px;
    }
}

@media only screen and (min-width: 1600px) {
    .contact-card {
        width: 30% !important;
    }
}

@media only screen and (max-width: 600px) {
    .mobile-modal {
        margin: 0.1em;
        padding: 0;
        width: 100%;
    }
    .vg-mobile {
        font-size: 12px;
        width: 24rem;
    }
    .vg-mobile-card {
        width: 95%;
    }
}

@media only screen and (max-width: 1199px) {
    .mobile-modal {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .modal-image {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}

@media only screen and (max-width: 350px) {
    .email-text {
        font-size: 0.8rem !important;
    }
}

@media only screen and (max-width: 400px) {
    .mobile-buttons {
        padding: 0.75em !important;
        margin-left: 0rem !important;
    }
    .mobile-buttons .m-1 {
        margin-left: 0rem !important;
    }
    .vg-mobile {
        width: 22rem;
    }
}
